import axiosInstance from "./axiosInstance";

const configService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.get("/config/all");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  update: async (payload) => {
    try {
      const response = await axiosInstance.put("/config/update", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default configService;
