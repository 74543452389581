import React, { useEffect, useState } from "react";
import ButtonLoading from "../../components/ButtonLoading";
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addContent,
  getContent,
  successListener,
  updateContent,
} from "../../api/reducers/content";

const WebContentAddEdit = ({ edit = false }) => {
  const names = ["title", "description", "page"];
  const [error, setErrors] = useState([false, false]);
  const [assignLoading, setAssignLoading] = useState(false);

  const [contentData, setContentData] = useState({
    title: "",
    description: "",
    page: "",
  });

  const response = useSelector((state) => state.content);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (edit) {
      if (params.get("id")) {
        dispatch(getContent(params.get("id")));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (edit) {
      if (response.content) {
        setContentData(response.content);
      }
    }
  }, [response.content]);

  useEffect(() => {
    if (response.success) {
      navigate("/content");
      dispatch(successListener());
    }
  }, [response.success]);

  const handleChange = (e) => {
    let tempErrors = [...error];
    tempErrors[names.indexOf(e.target.name)] = false;
    setErrors(tempErrors);
    setContentData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    let tempErrors = [...error];
    for (let i = 0; i < names.length; i++) {
      let name = names[i];
      tempErrors[i] = contentData[name].length === 0;
    }
    setErrors(tempErrors);
    if (!tempErrors.includes(true)) {
      setAssignLoading(true);
      let data = { ...contentData };
      console.log("🚀 ~ handleSubmit ~ data:", data);
      //   data.page = data.title.toLowerCase().replaceAll(" ", "-");
      if (edit) {
        dispatch(updateContent(data)).then(() => {
          setAssignLoading(false);
        });
      } else {
        dispatch(addContent(data)).then(() => {
          setAssignLoading(false);
        });
      }
    }
  };

  return (
    <>
      {/*{response.blogLoading ? (*/}
      {/*    <Loading/>*/}
      {/*) : (*/}
      <div>
        <h1 className="text-center font-[800] text-[25px] uppercase mt-5">
          Web Content
        </h1>
        <div className="bg-white md:mt-[3rem] mt-3 rounded-xl lg:px-[8rem] px-2 md:py-16 py-8 flex flex-col md:mx-8 mx-auto">
          <div>
            <div className="w-[100%] px-5 py-2">
              <label className="block text-[12px] ml-3 font-medium uppercase">
                Page
              </label>
              <input
                value={contentData.page}
                type="text"
                name={names[2]}
                onChange={(e) => handleChange(e)}
                className={`pl-4 block py-[9px] w-full text-sm bg-gray-50 rounded-[9px] border-[1px]  ${
                  error[0] ? "border-red-600" : "border-gray-300"
                }`}
                placeholder="Enter Page Name"
              />
            </div>
          </div>

          <div>
            <div className="w-[100%] px-5 py-2">
              <label className="block text-[12px] ml-3 font-meddium uppercase">
                Title
              </label>
              <input
                value={contentData.title}
                type="text"
                name={names[0]}
                onChange={(e) => handleChange(e)}
                className={`pl-4 block py-[9px] w-full text-sm bg-gray-50 rounded-[9px] border-[1px]  ${
                  error[0] ? "border-red-600" : "border-gray-300"
                }`}
                placeholder="Enter Title"
              />
            </div>
          </div>
          <div className="w-[100%] px-5 py-2">
            <label className="block text-[12px] ml-3 font-medium uppercase">
              Content
            </label>
            <div
              className={`rounded-[5px] border-[1px] ${
                error[4] ? "border-red-600" : "border-gray-300"
              }`}
            >
              <JoditEditor
                // ref={editor}
                value={contentData.description}
                name={names[1]}
                tabIndex={1}
                onChange={(v) =>
                  handleChange({ target: { name: names[1], value: v } })
                }
              />
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <button
              disabled={assignLoading}
              onClick={() => handleSubmit()}
              className="bg-blue-600 text-white py-2 px-8 rounded-xl font-semibold text-[15px] uppercase"
            >
              {assignLoading ? <ButtonLoading /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
      {/*)}*/}
    </>
  );
};

export default WebContentAddEdit;
