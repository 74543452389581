"use client";

import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ContactReplyModal from "../../components/ContactReplyModal";
import contactService from "../../api/services/contactService";
import { useLocation } from "react-router-dom";

const Detail = ({ params }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [open, setOpen] = useState(false);
  const [contactData, setContactData] = useState({});

  useEffect(() => {
    const getSingleContact = async () => {
      const resp = await contactService.getSingleContact(id);
      if (resp) {
        setContactData(resp?.contact);
      } else {
        setContactData({});
      }
    };

    getSingleContact();
  }, []);

  return (
    <div>
      <ContactReplyModal open={open} setOpen={setOpen} />
      <h1 className="text-center font-[600] text-xl uppercase ">
        Contact us Detail
      </h1>
      <div className="flex flex-row-reverse mt-6">
        <Button
          onClick={() => setOpen(!open)}
          variant="contained"
          className="mt-2 "
        >
          Reply
        </Button>
      </div>

      <div className="flex-col w-[30%] mx-auto justify-center  items-center border-[1px] border-gray-400 p-8 rounded-md mt-10">
        <div className="flex gap-2 justify-between">
          <label>First name</label>
          <p>:</p>
          <p>{contactData?.first_name || "fetching..."} </p>
        </div>
        <div className="flex gap-2 justify-between mt-2">
          <label>Last name</label>
          <p>:</p>
          <p>{contactData?.last_name || "fetching..."}</p>
        </div>

        <div className="flex gap-2 justify-between mt-2">
          <label>Email</label>
          <p>:</p>
          <p>{contactData?.email || "fetching..."}</p>
        </div>

        {contactData?.type === "SERVICE PROVIDER" && (
          <div className="flex gap-2 justify-between mt-2">
            <label>Company</label>
            <p>:</p>
            <p>{contactData?.company || "fetching..."}</p>
          </div>
        )}

        {contactData?.type === "SERVICE PROVIDER" && (
          <div className="flex gap-2 justify-between mt-2">
            <label>Phone</label>
            <p>:</p>
            <p>{contactData?.phone || "fetching..."}</p>
          </div>
        )}

        {contactData?.type === "SERVICE PROVIDER" && (
          <div className="flex gap-2 justify-between mt-2">
            <label>Topic</label>
            <p>:</p>
            <p>{contactData?.phone || "fetching..."}</p>
          </div>
        )}

        <div className="flex gap-2 justify-between mt-2">
          <label>Type</label>

          <p>:</p>
          <p>{contactData?.type || "fetching..."}</p>
        </div>
        <div className="flex gap-2 justify-between mt-2">
          <label>Message</label>
          <p>:</p>
          <p>{contactData?.message || "fetching..."}</p>
        </div>
      </div>
    </div>
  );
};

export default Detail;
