import React, { useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Button, CircularProgress, LinearProgress } from "@mui/material";

const BulkUploadPage = () => {
  const [fileData, setFileData] = useState([]);
  const [loader, setLoading] = useState(false); // Loader visibility
  const [progress, setProgress] = useState(0); // Track progress percentage
  const [status, setStatus] = useState(""); // Display status message
  console.log("🚀 ~ BulkUploadPage ~ csvData:", fileData);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();
    console.log("🚀 ~ handleFileUpload ~ fileExtension:", fileExtension);

    if (fileExtension === "csv") {
      handleCSV(file);
    } else if (fileExtension === "xlsx") {
      handleXLSX(file);
    } else {
      alert("Please upload a valid .csv or .xlsx file");
    }
  };

  const handleCSV = (file) => {
    setLoading(true);
    setStatus("Processing CSV...");

    Papa.parse(file, {
      complete: (result) => {
        setFileData(result.data);
        setLoading(false);
        setStatus("Upload Complete!");
      },
      header: true, // Consider first row as headers
    });
  };

  const handleXLSX = (file) => {
    setLoading(true);
    setStatus("Processing XLSX...");

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      setFileData(jsonData);
      setLoading(false);
      setStatus("Upload Complete!");
    };

    reader.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentComplete = Math.round((e.loaded / e.total) * 100);
        setProgress(percentComplete);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="w-full h-full flex flex-col gap-3 ">
      <div className="flex flex-row items-center gap-3">
        <h2 className="text-xl font-bold ">Csv Data Import :</h2>
        <div>
          <input
            id="fileInput"
            type="file"
            accept=".xlsx, .csv"
            onChange={handleFileUpload}
            className=""
          />
          {/* {loader && (
            <>
              <div className="flex item-center gap-2 mt-1">
                <CircularProgress />
                <h1>{progress}%</h1>
              </div>
              <div className="mt-2">
                <LinearProgress variant="determinate" value={progress} />
              </div>
            </>
          )}
          {status && { status }} */}
        </div>
      </div>
      <div className="bg-gray-100 border border-black shadow-md h-[750px] overflow-y-scroll">
        Content
      </div>
    </div>
  );
};

export default BulkUploadPage;
