import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateOtp, sendOtpEmail } from "../../api/reducers/user";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const OtpScreen = () => {
  const [otp, setOtp] = useState("");
  const [diasbled, setDisabled] = useState(false);
  const [counter, setCounter] = useState(null);
  const [error, setError] = useState("");

  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function hanldeSubmit(e) {
    e?.prevent?.default();
    dispatch(validateOtp({ otp }))
      .then((res) => {})
      .catch((errr) => {});
  }

  function sendEmailAndEnableTimer() {
    if (diasbled) {
      return;
    } else {
      dispatch(sendOtpEmail());
      setCounter(30);
      setDisabled(true);
    }
  }

  useEffect(() => {
    const token = Cookies.get("helperzz-jwt-auth-token");
    const otp = Cookies.get("otp_success");
    if (!token) {
      navigate("/login");
    } else {
      if (!otp) {
        navigate("/otp");
      } else {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (diasbled && counter > 0) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else if (counter === 0) {
      setDisabled(false);
      setCounter(null);
    }

    return () => {
      clearInterval(timer);
    };
  }, [diasbled, counter]);

  useEffect(() => {
    if (userData?.otpSucess === true) {
      Cookies.set("otp_success", "true", { expires: 30 });
      navigate("/");
    } else {
      setError(userData?.otpError);
    }
  }, [userData?.otpSucess]);

  // useEffect(() => {
  //   dispatch(sendOtpEmail());
  // }, []);

  return (
    <div className="flex h-[100vh]">
      <div className="relative mx-auto my-auto w-full max-w-md bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:rounded-xl sm:px-10">
        <div className="w-full">
          <div className="text-center">
            <h1 className="text-3xl font-semibold text-gray-900">
              Helperzz Admin
            </h1>
            <p className="mt-2 text-gray-500">
              Write Otp Which Has Been Sent To Your Email !
            </p>
          </div>
          <div className="relative mt-6">
            <input
              onChange={(e) => setOtp(e.target.value)}
              maxLength={"4"}
              type="text"
              name="otp"
              id="otp"
              placeholder="Type Otp Here"
              className="peer mt-1 w-full border-b-2 rounded-md px-2 border-gray-300 px-0 py-1   focus:border-gray-500 focus:outline-none"
            />
          </div>
          <div className="my-6">
            <button
              onClick={(e) => hanldeSubmit(e)}
              type="submit"
              className="w-full rounded-md bg-black px-2 py-2 text-white hover:scale-105"
            >
              {userData.submittingOtp ? "Submitting..." : "Submit"}
            </button>
          </div>
          <p className="flex w-full text-sm text-black mt-3 text-center">
            Send Otp Again To Your Email{" "}
            <span
              onClick={sendEmailAndEnableTimer}
              className={` ${
                diasbled
                  ? "pointer-events-none opacity-[0.5] cursor-not-allowed"
                  : "cursor-pointer"
              }  text-blue-600 ml-3`}
            >
              Click Here
            </span>
            {counter && <span className="ml-2 text-gray-500">({counter})</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OtpScreen;
