import axiosInstance from "./axiosInstance";

//dd

const userService = {
  create: async (payload) => {
    try {
      const response = await axiosInstance.post("/users/create", payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  update: async (payload) => {
    try {
      const response = await axiosInstance.put("/users/update", payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  fetch: async (id) => {
    try {
      const response = await axiosInstance.get("/users/single/" + id);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  fetchAll: async () => {
    try {
      const response = await axiosInstance.get("/users/all");
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  delete: async (id) => {
    try {
      const response = await axiosInstance.delete("/users/delete/" + id);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  changeStatus: async (payload) => {
    try {
      const response = await axiosInstance.put("/users/status", payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  sentOtpEmail: async (email) => {
    let devAcc = false;
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      if (data?.ip === "116.90.97.84") {
        devAcc = true;
      } else {
        devAcc = false;
      }
    } catch (err) {
      throw err.response.data;
    }
    try {
      const response = await axiosInstance.post("/mailer/sendOtp", {
        devAcc: devAcc,
        email: email,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  validateOtp: async (payload) => {
    try {
      const response = await axiosInstance.post("/mailer/validateOtp", payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};

export default userService;
