import React, { useState, useEffect } from "react";
import { getAllConfig, updateConfig } from "../../api/reducers/config";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import ButtonLoading from "../../components/ButtonLoading";
import { updateListner } from "../../api/reducers/config";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const WebsiteConfiguration = () => {
  const names = ["web_email", "address", "phone"];
  const [errors, setErrors] = useState([false, false, false]);
  const [assignLoading, setAssignLoading] = useState(false);

  const navigate = useNavigate();

  const response = useSelector((state) => state.config);
  console.log("🚀 ~ WebsiteConfiguration ~ response:", response);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  console.log("🚀 ~ WebsiteConfiguration ~ formData:", formData);

  useEffect(() => {
    dispatch(getAllConfig());
  }, []);

  useEffect(() => {
    setFormData(response?.configData);
  }, [response.configData]);

  const handleChange = (e) => {
    console.log("🚀 ~ handleChange ~ e:", e);
    let tempErrors = [...errors];
    tempErrors[names.indexOf(e.target.name)] = false;
    setErrors(tempErrors);
    setFormData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    try {
      setAssignLoading(true);
      dispatch(updateConfig(formData));
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  useEffect(() => {
    if (response?.update === "updated") {
      dispatch(updateListner());
      setAssignLoading(false);
      toast.success("Config Updated Sucessfully!");
      navigate("/settings");
    } else if (response?.update === "got error") {
      setAssignLoading(false);
      toast.error("cant update config !");
      dispatch(updateListner());
    }
  }, [response]);

  return (
    <>
      {response?.loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex justify-start w-[100%] m-auto">
            <h1 className="lg:text-3xl md:text-2xl text-xl font-[700]">
              Website Configuration
            </h1>
          </div>
          <div className="bg-white md:mt-[3rem] mt-3 rounded-xl lg:px-[8rem] px-2 md:py-16 py-8 flex flex-col md:mx-8 mx-auto">
            <div className="w-[100%] px-5 py-2">
              <label className="block text-[12px] ml-3 font-medium uppercase">
                Website Email
              </label>
              <input
                value={formData?.web_email}
                onChange={(e) => handleChange(e)}
                type="text"
                name={names[0]}
                placeholder="Enter The Email Of website to show !"
                className={`pl-4 block py-[9px] w-full text-sm bg-gray-50 rounded-[9px] border-[1px] focus:border-black focus:outline-none mt-1 ${
                  errors[0] ? "border-red-600" : "border-gray-300"
                }`}
              />
            </div>

            <div className="w-[100%] px-5 py-2">
              <label className="block text-[12px] ml-3 font-medium uppercase">
                Website Address
              </label>
              <input
                value={formData?.address}
                onChange={(e) => handleChange(e)}
                type="text"
                name={names[1]}
                placeholder="Enter The Website address"
                className={`pl-4 block py-[9px] w-full text-sm bg-gray-50 rounded-[9px] border-[1px] focus:border-black focus:outline-none mt-1 ${
                  errors[0] ? "border-red-600" : "border-gray-300"
                }`}
              />
            </div>

            <div className="w-[100%] px-5 py-2">
              <label className="block text-[12px] ml-3 font-medium uppercase">
                Website Phone
              </label>
              <input
                value={formData?.phone}
                onChange={(e) => handleChange(e)}
                type="text"
                name={names[2]}
                placeholder="Enter The Website phone number"
                className={`pl-4 block py-[9px] w-full text-sm bg-gray-50 rounded-[9px] border-[1px] focus:border-black focus:outline-none mt-1 ${
                  errors[0] ? "border-red-600" : "border-gray-300"
                }`}
              />
            </div>

            <div className="flex justify-center mt-12">
              <button
                disabled={assignLoading}
                onClick={() => handleSubmit()}
                className="bg-blue-600 text-white py-2 px-8 rounded-xl font-semibold text-[15px] uppercase"
              >
                {assignLoading ? <ButtonLoading /> : "Update"}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WebsiteConfiguration;
