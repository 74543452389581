import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import configService from "../services/configService";
import {
  ALL_CONFIG,
  UPDATE_CONFIG,
  CONFIG_REDUCER,
} from "../../utils/constants";

// Initial state
const initialState = {
  configData: {},
  loading: false,
  noData: false,
  success: false,
  error: null,
  update: "not updating",
};

// Async thunk for fetching contact data
export const getAllConfig = createAsyncThunk(ALL_CONFIG, async () => {
  const response = await configService.getAll();
  return response;
});

export const updateConfig = createAsyncThunk(UPDATE_CONFIG, (payload) => {
  return configService.update(payload).then((response) => {
    if (response.success) {
      return response.message;
    } else {
      return null;
    }
  });
});

// config slice
const config = createSlice({
  name: CONFIG_REDUCER,
  initialState,
  reducers: {
    updateListner: (state) => {
      state.update = "not updating";
    },
  },
  extraReducers: (builder) => {
    // GET ALL CONFIG

    builder
      .addCase(getAllConfig.pending, (state, action) => {
        console.log("state pending", action);

        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getAllConfig.fulfilled, (state, action) => {
        console.log("state fulfilled", action);
        state.loading = false;
        state.configData = action.payload.configs || {};
        state.success = true;
        state.noData = Object.keys(action.payload.configs).length === 0;
        state.error = null;
      })
      .addCase(getAllConfig.rejected, (state, action) => {
        console.log("state rejected", action);
        state.loading = false;
        state.noData = true;
        state.success = false;
        state.error = action.error.message;
      });

    //UPDATE CONFIG
    builder.addCase(updateConfig.pending, (state) => {
      state.update = "updating";
      state.error = null;
    });
    builder.addCase(updateConfig.fulfilled, (state, action) => {
      state.update = "updated";
      state.success = true;
      state.error = null;
    });
    builder.addCase(updateConfig.rejected, (state, action) => {
      state.update = "got error";
      state.updating = false;
      state.success = false;
      state.error = action.error.message;
    });
  },
});

export default config.reducer;
export const { updateListner } = config.actions;
