import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import contentService from "../services/contentService";
import {
    ALL_CONTENT,
    CONTENT_REDUCER,
    CREATE_CONTENT,
    DELETE_CONTENT,
    SINGLE_CONTENT,
    UPDATE_CONTENT
} from "../../utils/constants";

const initialState = {
    loading: false,
    contentLoading: false,
    deleting: false,
    noData: false,
    success: false,
    fetched: false,
    contents: [],
    content: null,
    error: '',
    contentError: '',
}

export const addContent = createAsyncThunk(CREATE_CONTENT, (content) => {
    return contentService.create(content)
})

export const updateContent = createAsyncThunk(UPDATE_CONTENT, (content) => {
    return contentService.update(content).then(response => {
        if (response.success) {
            return content
        } else {
            return null
        }
    })
})

export const getContents = createAsyncThunk(ALL_CONTENT, () => {
    return contentService.fetchAll()
})

export const getContent = createAsyncThunk(SINGLE_CONTENT, (id) => {
    return contentService.fetch(id)
})

export const deleteContent = createAsyncThunk(DELETE_CONTENT, (id) => {
    return contentService.delete(id).then(response => {
        if (response.success){
            return id
        } else {
            return 0
        }
    })
})


const content = createSlice({
    name: CONTENT_REDUCER,
    initialState,
    reducers: {
        successListener: (state) => {
            state.success = false
        }
    },
    extraReducers: builder => {
        //GET ALL USERS /////////////////////////////////
        builder.addCase(getContents.pending, state => {
            state.loading = true
        })
        builder.addCase(getContents.fulfilled, (state, action) => {
            state.loading = false
            state.contents = action.payload.contents
            state.error = ''
            state.fetched = true
        })
        builder.addCase(getContents.rejected, (state, action) => {
            state.loading = false
            state.contents = []
            state.error = action.error.message
        })

        //GET USER /////////////////////////////////////
        builder.addCase(getContent.pending, state => {
            state.contentLoading = true
        })
        builder.addCase(getContent.fulfilled, (state, action) => {
            state.contentLoading = false
            state.content = action.payload.content
            state.contentError = ""
        })
        builder.addCase(getContent.rejected, (state, action) => {
            state.contentLoading = false
            state.content = null
            state.contentError = action.error.message
        })

        //ADD USER /////////////////////////////////////////
        builder.addCase(addContent.pending, state => {
            state.success = false
        })
        builder.addCase(addContent.fulfilled, (state, action) => {
            state.success = true
            let tempContents = [...state.contents]
            let content = action.payload.content
            tempContents.unshift(content)
            state.contents = tempContents
        })
        builder.addCase(addContent.rejected, (state, action) => {
            state.success = false
        })

        //EDIT USER //////////////////////////////////////////
        builder.addCase(updateContent.pending, state => {
            state.success = false
        })
        builder.addCase(updateContent.fulfilled, (state, action) => {
            state.success = true
            if (action.payload) {
                const value = state.contents.find(v => v.id === action.payload.id)
                Object.assign(value, action.payload)
            }
        })
        builder.addCase(updateContent.rejected, (state, action) => {
            state.success = false
        })

        //DELETE USER ////////////////////////////////////////
        builder.addCase(deleteContent.pending, state => {
            state.deleting = true
        })
        builder.addCase(deleteContent.fulfilled, (state, action) => {
            state.deleting = false
            state.contents = state.contents.filter((value) => value.id !== action.payload)
        })
        builder.addCase(deleteContent.rejected, (state, action) => {
            state.deleting = false
        })
    }
})

export default content.reducer
export const { successListener } = content.actions
