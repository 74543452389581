import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import contactService from "../services/contactService";
import { CONTACT_US, DELETE_CONTACT } from "../../utils/constants";

// Initial state
const initialState = {
  data: [],
  loading: false,
  noData: false,
  success: false,
  error: null,
  deleting: false,
};

// Async thunk for fetching contact data
export const getContactUs = createAsyncThunk(CONTACT_US, async () => {
  const response = await contactService.getContact();
  return response;
});

export const deleteContact = createAsyncThunk(DELETE_CONTACT, (id) => {
  return contactService.deleteContact(id).then((response) => {
    if (response.success) {
      return id;
    } else {
      return 0;
    }
  });
});
// Contact slice
const contact = createSlice({
  name: CONTACT_US,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getContactUs.pending, (state, action) => {
        console.log("state pending", action);
        state.loading = true;
        state.noData = false;
        state.success = false;
        state.error = null;
      })
      .addCase(getContactUs.fulfilled, (state, action) => {
        console.log("state fulfilled", action);
        state.loading = false;
        state.data = action.payload.contacts || [];
        state.success = true;
        state.noData = action.payload.length === 0;
        state.error = null;
      })
      .addCase(getContactUs.rejected, (state, action) => {
        console.log("state rejected", action);
        state.loading = false;
        state.noData = true;
        state.success = false;
        state.error = action.error.message;
      });

    //DELETE_CONTACT
    builder.addCase(deleteContact.pending, (state) => {
      state.deleting = true;
    });
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.deleting = false;
      state.data = state.data.filter((value) => value.id !== action.payload);
    });
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.deleting = false;
    });
  },
});

export default contact.reducer;
