import axiosInstance from "./axiosInstance";

const contactService = {
  getContact: async () => {
    try {
      const response = await axiosInstance.get("/contact/all");
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  deleteContact: async (id) => {
    try {
      const response = await axiosInstance.delete("/contact/delete/" + id);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getSingleContact: async (id) => {
    try {
      const response = await axiosInstance.get("/contact/single/" + id);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
export default contactService;
